import React from "react";
import ReactDOM from "react-dom";

import '../../assets/styles/sass/styles.scss';


import App from "./App";


ReactDOM.render(
    <App />, document.getElementById("app")
);